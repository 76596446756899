import * as React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from "gatsby"

import Seo from "../components/Seo"
import Hero from '../components/Hero'
import RegistrationBanner from '../components/RegistrationBanner'
import SignUpSection from "../components/SignUpSection"

export default function voterRegistration({ data }) {
  const pageData = data.markdownRemark.frontmatter;

  return(
    <>
      <Seo title = {pageData.page_title} />

      <Hero
        headline = {pageData.hero_headline} 
        size = {'medium'}
      >
        <StaticImage 
          className="hero__picture"
          src="../../static/media/updates-hero.jpg" 
          alt="" 
          loading="eager"
        />
      </Hero>

      <div className="section-registration">
        <div className="container">
          <div className="row">
            <RegistrationBanner type="main" />
          </div>
        </div>
      </div>

      <SignUpSection />
    </>
  )
}


// = = = = = = = = 
// GraphQL
export const query = graphql`
  query VoterRegistration {
    markdownRemark(frontmatter: {page_title: {eq: "Voter Registration"}}) {
      frontmatter {
        page_title
        hero_headline
        hero_ctas {
          button_label
          button_url
        }
      }
    }
  }
`;